<template>
    <v-sheet>
        <div class="embed-responsive">
            <iframe class="embed-responsive-item"
                    :src="link"
                    allowfullscreen></iframe>
        </div>
    </v-sheet>
</template>
<script>
    export default {
        props: ['link']
    }
</script>
<style scoped>
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 20px;
    }

    .embed-responsive {
        position: relative;
        display: block;
        padding: 0;
        overflow: hidden;
        width: 100% !important;
        height: 90vh !important;
    }

    .embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0
    }

    .embed-responsive-16by9 {
        padding-bottom: 56.25%
    }

    .embed-responsive-4by3 {
        padding-bottom: 75%
    }

    @media (max-width: 600px) {
        #video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 200px;
        }
    }
</style>