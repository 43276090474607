<template>
  <div>
    <show-video v-if="content && content.video" :link="content.video.link" :key="content.id"></show-video>
    <!--    <take-exam v-else-if="content && content.exam && content.exam.mode === 'exam'" :content="content"-->
    <!--               :exam="content.exam" :key="content.id"-->
    <!--               @submitted="loadCourse"></take-exam>-->
    <!--    <take-group-exam v-else-if="content && content.exam && content.exam.mode === 'group_exam'" :content="content"-->
    <!--                     :exam_id="content.exam.id" :key="content.id"-->
    <!--                     @submitted="loadCourse"></take-group-exam>-->
    <!--    <practice-exam v-else-if="content && content.exam && content.exam.mode === 'practice'" :content="content"-->
    <!--                   :exam="content.exam" :key="content.id"-->
    <!--                   @submitted="loadCourse"></practice-exam>-->
    <web-exam v-else-if="content && content.exam" :initial_exam="content.exam"></web-exam>
    <show-pdf v-else-if="content && content.pdf" :link="content.pdf.link" :key="content.id"></show-pdf>
    <read-note v-else-if="content && content.note" :note="content.note" :key="content.id"></read-note>
  </div>
</template>

<script>
import ShowVideo from "./ShowVideo";
import ShowPdf from "./ShowPdf";
import ReadNote from "./ReadNote";
import axios from "axios";
import WebExam from "../exam/WebExam";

export default {
  name: "Content",
  components: {WebExam, ReadNote, ShowPdf, ShowVideo},
  data() {
    return {
      content: {}
    }
  },
  methods: {
    initialize() {
      const url = 'contents/' + this.$route.params.contentId
      axios.get(url).then(resonse => {
        this.content = resonse.data
      })
    }
  },
  created() {
    this.initialize()
  }
}
</script>

<style scoped>

</style>